'use client';

import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { getAccount } from '@wagmi/core';

import Cookies from 'js-cookie';
import { SiweMessage } from 'siwe';
import { Address } from 'viem';
import { useAccount, useConnect, useDisconnect, useSignMessage } from 'wagmi';

import { useGetSIWENonceMutation } from '@query';

import { RequestSignResult, SignType, WalletType } from '@api';

import { useUserAgentDetect } from '@shared/common/providers/UserAgentDetectProvider';
import { wagmiAdapter, WagmiConfigChain } from '@shared/common/providers/Web3Provider/wagmi';
import { DEFAULT_TARGET_CHAIN, namedWeb3Errors, storageKeys } from '@shared/constants';
import { normalizeError } from '@shared/helpers/displayErrorToast';
import displayWalletErrorToast, {
  WALLET_ERROR_TOAST_ACTION_SCOPE,
} from '@shared/helpers/displayWalletErrorToast';
import checkConnectorReadiness from '@shared/helpers/web3/checkConnectorReadiness';
import clearSavedConnectionDetails from '@shared/helpers/web3/clearSavedConnectionDetails';
import getOriginWalletAddressCase from '@shared/helpers/web3/getOriginWalletAddressCase';
import getSignTypeFlags from '@shared/helpers/web3/getSignTypeFlags';
import getToastIcon from '@shared/helpers/web3/getToastIcon';
import getWalletActionStyles from '@shared/helpers/web3/getWalletActionStyles';
import getWalletTypeFlags from '@shared/helpers/web3/getWalletTypeFlags';
import handleWalletConnectDeepLink from '@shared/helpers/web3/handleWalletConnectDeepLink';
import launchAsyncToast from '@shared/helpers/web3/launchAsyncToast';
import launchConfirmToast from '@shared/helpers/web3/launchConfirmToast';
import saveConnectionToCookies from '@shared/helpers/web3/saveConnectionToCookies';
import solana from '@shared/helpers/web3/solana';
import switchChainUtil from '@shared/helpers/web3/switchChainUtil';
import truncate from '@shared/helpers/web3/truncate';
import verifyEVMSignature from '@shared/helpers/web3/verifyEVMSignature';
import useWeb3ModalConnectWalletAsync from '@shared/hooks/web3/useWeb3ModalConnectWalletAsync';

import { useAppKit, useAppKitState } from '@reown/appkit/react';

export interface ConnectionProps {
  walletType: WalletType;
  targetAddress?: string;
  hideToasts?: boolean;
  disabledCache?: boolean;
  chainId?: WagmiConfigChain['id'];
  shouldWaitWeb3Modal?: boolean;
  shouldReconnect?: boolean;
}

export interface RequestSignProps extends Pick<ConnectionProps, 'walletType' | 'targetAddress'> {
  message: string;
  hideConfirmToast?: boolean;
  skipConnectStep?: boolean;
  hideConnectToasts?: boolean;
  shouldVerifyChain?: boolean;
  signType?: SignType;
  nonce?: string;
  shouldReconnect?: boolean;
}

export default function useWeb3() {
  const web3Account = useAccount();
  const { open: openWeb3Modal } = useAppKit();
  const { open: isWeb3ModalOpen } = useAppKitState();
  const { connectAsync, connectors } = useConnect();
  const { disconnectAsync } = useDisconnect();
  const { signMessageAsync } = useSignMessage();
  const { isDesktop } = useUserAgentDetect();
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: getSIWENonce } = useGetSIWENonceMutation();
  const web3ModalCloseResolverRef = useRef<((address: Address | undefined) => void) | null>(null);
  const connectWalletAsync = useWeb3ModalConnectWalletAsync();

  useEffect(() => {
    if (!isWeb3ModalOpen && web3Account.address) {
      web3ModalCloseResolverRef.current?.(web3Account.address);

      web3ModalCloseResolverRef.current = null;
    }
  }, [isWeb3ModalOpen, web3Account.address]);

  const disconnectAndClearConnection = async () => {
    await disconnectAsync();
    clearSavedConnectionDetails();
  };

  interface ConnectAndGetWalletAddressProps {
    walletType: WalletType;
    disabledCache?: boolean;
    chainId?: WagmiConfigChain['id'];
    shouldWaitWeb3Modal?: boolean;
    shouldReconnect?: boolean;
  }

  const connectAndGetWalletAddress = async ({
    walletType,
    disabledCache,
    chainId = DEFAULT_TARGET_CHAIN.id,
    shouldWaitWeb3Modal,
    shouldReconnect,
  }: ConnectAndGetWalletAddressProps): Promise<Address> => {
    const { isPhantom, isWalletConnect, isWalletConnect2, isPasskey } =
      getWalletTypeFlags(walletType);

    if (isPhantom) {
      try {
        return (await solana.getAddress()) as Address;
      } catch (error) {
        throw new Error('An error occurred while connecting to Solana');
      }
    }

    if (isPasskey) {
      if (!shouldReconnect && web3Account.address) {
        return web3Account.address;
      }

      const passkeyConnector = connectors.find((connector) => connector.id === 'network.passkeys');

      if (!passkeyConnector) {
        throw new Error('Passkey is not available');
      }

      const connection = await connectAsync({ connector: passkeyConnector });
      const passkeyAccount = connection.accounts[0];

      if (!passkeyAccount) {
        throw new Error(namedWeb3Errors.PLEASE_CONNECT_TO_PROCEED);
      }

      return passkeyAccount;
    }

    if (isWalletConnect2) {
      if (!shouldReconnect && web3Account.address) {
        return web3Account.address;
      }

      const connectedWallet = await connectWalletAsync();

      if (!connectedWallet?.address) {
        throw new Error(namedWeb3Errors.PLEASE_CONNECT_TO_PROCEED);
      }

      return connectedWallet.address;
    }

    if (isWalletConnect) {
      if (web3Account.address) {
        return web3Account.address;
      }

      await openWeb3Modal({ view: 'Connect' });

      if (!shouldWaitWeb3Modal) {
        throw new Error(namedWeb3Errors.PLEASE_CONNECT_TO_PROCEED);
      }

      // NOTE: at this point web3 modal should be opened
      // to handle close event of the web3 modal we are creating promise that will be resolved on modal close
      // we are handling modal close in useEffect using open state that useWeb3ModalState returns
      const address = await new Promise<Address | undefined>((resolve) => {
        web3ModalCloseResolverRef.current = resolve;
      });

      if (!address) {
        throw new Error(namedWeb3Errors.PLEASE_CONNECT_TO_PROCEED);
      }

      return address;
    }

    if (
      !disabledCache &&
      Cookies.get(storageKeys.MSQ_CONNECTED_WALLET_ADDRESS) &&
      Cookies.get(storageKeys.MSQ_CONNECTED_WALLET_TYPE) === walletType
    ) {
      return Cookies.get(storageKeys.MSQ_CONNECTED_WALLET_ADDRESS) as Address;
    }

    const connector = web3Account.connector || connectors[0];

    const connection = await connectAsync({ chainId, connector });

    return connection.accounts[0];
  };

  const connectWallet = async (props: ConnectionProps): Promise<string> => {
    const {
      walletType,
      targetAddress,
      hideToasts = false,
      disabledCache,
      chainId,
      shouldWaitWeb3Modal,
      shouldReconnect,
    } = props;
    const toastId = new Date().getTime();

    try {
      const { isPhantom } = getWalletTypeFlags(walletType);
      const toastIcon = getToastIcon('EVM');
      const toastStyle = getWalletActionStyles('EVM');

      if (!hideToasts) {
        const toastMessage = targetAddress
          ? `Connecting to ${truncate(targetAddress)}`
          : `Connecting...`;

        toast.loading(toastMessage, {
          toastId,
          icon: toastIcon,
          style: toastStyle,
          closeOnClick: true,
        });
      }

      const rawAddress = await connectAndGetWalletAddress({
        walletType,
        disabledCache,
        chainId,
        shouldWaitWeb3Modal,
        shouldReconnect,
      });

      if (!rawAddress) {
        throw new Error(namedWeb3Errors.EMPTY_ADDRESS);
      }

      let userAddress = isPhantom ? rawAddress : getOriginWalletAddressCase(rawAddress);

      if (targetAddress && targetAddress.toLowerCase() !== userAddress.toLowerCase()) {
        userAddress = await connectAndGetWalletAddress({
          walletType,
          disabledCache,
          chainId,
          shouldWaitWeb3Modal,
          shouldReconnect: true,
        });
      }

      toast.update(toastId, {
        type: toast.TYPE.INFO,
        render: `Connected`,
        icon: toastIcon,
        style: toastStyle,
        autoClose: 3000,
        isLoading: false,
      });

      saveConnectionToCookies(userAddress, walletType);
      return userAddress;
    } catch (error) {
      toast.dismiss(toastId);

      const normalizedError: Error = normalizeError(error);

      if (normalizedError.message === namedWeb3Errors.CONNECTOR_ALREADY_CONNECTED) {
        await disconnectAndClearConnection();
        return connectWallet(props);
      }

      throw error;
    }
  };

  async function requestSign(props: RequestSignProps): Promise<RequestSignResult | undefined> {
    const {
      walletType,
      shouldVerifyChain = false,
      message,
      skipConnectStep,
      targetAddress,
      hideConnectToasts,
      hideConfirmToast,
      signType: signTypeFromProps = 'simple',
      nonce: nonceFromProps,
      shouldReconnect,
    } = props;
    let signType = signTypeFromProps;
    const { isPhantom, isWalletConnect, isWalletConnect2 } = getWalletTypeFlags(walletType);
    const { isReady, errorMessage } = checkConnectorReadiness(walletType);
    const { isSimpleSign, isSIWE, isSimpleWithNonce } = getSignTypeFlags(signType);

    if (isPhantom && !isSimpleSign) {
      signType = 'simple';
    }

    if (!isReady) {
      throw new Error(errorMessage);
    }

    const getUserSignature = (messageForSigning: string): Promise<string> => {
      if (isPhantom) {
        return solana.signMessage(messageForSigning);
      }

      return signMessageAsync({ message: messageForSigning });
    };

    const getSignMessage = async ({
      address,
      message: messageFromProps,
      nonce,
    }: {
      address: string;
      message: string;
      signType: SignType;
      nonce?: string;
    }): Promise<string> => {
      const msg = address ? `${messageFromProps}: ${truncate(address)}` : messageFromProps;

      switch (signType) {
        case 'simple': {
          return msg;
        }

        case 'simple-with-nonce':
          return `${msg}\nNonce: ${nonce}`;

        case 'SIWE': {
          if (!nonce) {
            throw new Error('Getting a nonce failed');
          }

          const { chainId } = getAccount(wagmiAdapter.wagmiConfig);

          try {
            return new SiweMessage({
              domain: window.location.host,
              address,
              statement: msg,
              uri: window.location.origin,
              version: '1',
              chainId,
              nonce,
            }).prepareMessage();
          } catch (error) {
            console.debug(error);

            throw new Error('SIWE Message creation failed');
          }
        }
      }
    };

    let nonce = nonceFromProps ?? '';

    try {
      let address!: string;
      let signature!: string;

      const isWalletConnectAccountConnected = web3Account.address && web3Account.isConnected;

      if (skipConnectStep && isWalletConnectAccountConnected) {
        address = getOriginWalletAddressCase(web3Account.address);
      } else {
        address = await connectWallet({
          walletType,
          targetAddress,
          hideToasts: hideConnectToasts,
          shouldReconnect,
        });
      }

      if ((isSIWE || isSimpleWithNonce) && !nonce && !isPhantom) {
        nonce = await getSIWENonce();
      }

      if (shouldVerifyChain) {
        const requirements = await switchChainUtil({ address, walletType });

        if (!requirements) {
          throw new Error('Failed to switch the chain. Please to it manually or try again later.');
        }
      }

      if (isWalletConnect) {
        handleWalletConnectDeepLink();
      }

      if (!hideConfirmToast) {
        await launchConfirmToast({ walletType, isDesktop });
      }

      const formattedMessage = await getSignMessage({ address, message, signType, nonce });
      const style = getWalletActionStyles('EVM');
      const toastId = new Date().getTime();

      if (!signature) {
        const showExtraInfo = isWalletConnect || (isWalletConnect2 && !isDesktop);

        signature = await Promise.race([
          getUserSignature(formattedMessage),
          launchAsyncToast({
            walletType,
            address,
            toastId,
            showExtraInfo,
          }),
        ]);
      }

      if (!isPhantom) {
        await verifyEVMSignature({
          address,
          message: formattedMessage,
          signature,
          signType,
          nonce,
        });
      }

      toast.dismiss(toastId);
      const toastMessage = 'Message signed';
      toast.success(toastMessage, { toastId: toastMessage, style });

      return {
        walletType,
        address: isPhantom ? address : address.toLowerCase(),
        network: isPhantom ? 'SOLANA' : 'EVM',
        message: formattedMessage,
        signature,
        v2: isSIWE,
      };
    } catch (error) {
      toast.dismiss();

      const normalizedError: Error = normalizeError(error);
      const newProps = { ...props, nonce };

      if (normalizedError.message === namedWeb3Errors.CONNECTOR_NOT_FOUND) {
        clearSavedConnectionDetails();
        return requestSign({ ...newProps, hideConfirmToast: true });
      }

      if (normalizedError.message === namedWeb3Errors.RECONNECT_REQUEST) {
        await disconnectAndClearConnection();
        return requestSign(newProps);
      }

      if (normalizedError.message === namedWeb3Errors.RETRY) {
        return requestSign(newProps);
      }

      displayWalletErrorToast({
        error,
        details: { walletType, scope: WALLET_ERROR_TOAST_ACTION_SCOPE.SIGN_MESSAGE_REQUEST },
      });
    }
  }

  return {
    connectWallet,
    requestSign,
    isLoading,
    setIsLoading,
    web3Account,
  };
}
