import {
  ChangeEvent,
  ComponentPropsWithoutRef,
  FormEvent,
  forwardRef,
  Ref,
  // useEffect,
  useState,
} from 'react';

import classNames from 'classnames';

import { cn } from '@ui/uikit/utils';

import { Cross, Search, Slash } from '../icons/mono';
import { Loading } from '../icons/mono/Loading';

interface SearchBarProps
  extends Omit<ComponentPropsWithoutRef<'input'>, 'value' | 'type' | 'onChange'> {
  value?: string;
  onChange?: (value: string) => void;
  onSearch?: (value: string) => void;
  onReset?: () => void;
  isOpen?: boolean;
  isLoading?: boolean;
  inputRef?: Ref<HTMLInputElement>;
  isMobileHeader: boolean;
  inputClassName?: string;
}

// TODO: refactor
const SearchBar = (
  {
    className,
    value: controlledValue,
    isOpen,
    onChange,
    onSearch,
    onReset,
    children,
    inputRef,
    isLoading,
    isMobileHeader,
    inputClassName,
    ...props
  }: SearchBarProps,
  ref: Ref<HTMLFormElement>,
) => {
  const [uncontrolledValue, setUncontrolledValue] = useState('');
  const isControlled = typeof controlledValue !== 'undefined';
  const value = isControlled ? controlledValue : uncontrolledValue;

  // useEffect(() => {
  //   const handleKeyDown = (e: KeyboardEvent) => {
  //     if (e.key === '/') {
  //       e.preventDefault();

  //       if (inputRef && 'current' in inputRef && inputRef.current) {
  //         inputRef.current.focus();
  //       }
  //     }
  //   };

  //   window.addEventListener('keydown', handleKeyDown);

  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, [inputRef]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!value?.trim()) {
      return;
    }

    if (onSearch) {
      onSearch(value);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value: targetValue } = e.target;

    if (onChange) {
      onChange(targetValue);
    }

    if (!isControlled) {
      setUncontrolledValue(targetValue);
    }
  };

  const handleReset = () => {
    if (onChange) {
      onChange('');
    }

    if (onReset) {
      onReset();
    }

    if (!isControlled) {
      setUncontrolledValue('');
    }
  };

  const showOptions = isOpen ?? !!value;

  const leftIconClassName =
    'size-4 absolute my-auto top-0 bottom-0 left-3 text-base-text-primary pointer-events-none';

  return (
    <form onSubmit={handleSubmit} ref={ref} className={cn('flex-1 relative', className)}>
      <div className="relative">
        {!isLoading && <Search className={leftIconClassName} />}

        {isLoading && <Loading className={cn(leftIconClassName, 'animate-loading-spin')} />}
        <input
          ref={inputRef}
          name="search"
          required
          type="search"
          value={value}
          onChange={handleChange}
          onReset={handleReset}
          className={classNames(
            'msq-input msq-search-input pl-[2.25rem] peer w-full',
            inputClassName,
          )}
          placeholder="Search for Projects, Hot Offers, Sales and more..."
          {...props}
        />
        <button
          type="reset"
          onClick={handleReset}
          className={cn(
            'msq-btn msq-btn-icon-sm msq-btn-tertiary peer-focus:msq-btn-secondary absolute right-2 top-2',
            'peer-valid:[&>:first-child]:hidden peer-valid:[&>:last-child]:flex',
            {
              'peer-focus:[&>:first-child]:hidden peer-focus:[&>:last-child]:flex': !isMobileHeader,
              'peer-invalid:hidden': isMobileHeader,
            },
          )}
        >
          <Slash className={cn('msq-btn-icon-child')} />
          <Cross className="msq-btn-icon-child hidden" />
        </button>
      </div>
      {/* TODO: refactor */}
      {!isMobileHeader && (
        <div
          className={classNames(
            'absolute bottom-0 pt-1 translate-y-full w-full left-0 max-w-[25rem]',
            {
              'pointer-events-none invisible': !showOptions,
            },
          )}
        >
          <div
            className={classNames('transition-all', {
              'opacity-0 -translate-y-1': !showOptions,
              'opacity-100 translate-y-0': showOptions,
            })}
          >
            <div className="w-full flex flex-col msq-sub-menu">{children}</div>
          </div>
        </div>
      )}
      {isMobileHeader && <div className="flex flex-col">{children}</div>}
    </form>
  );
};

export default forwardRef(SearchBar);
