import React, { SVGProps, useId } from 'react';

export const MagicAi = (props: SVGProps<SVGSVGElement>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 2 }, (_, i) => `${id}-${i}`);

  return (
    <svg {...props} viewBox="0 0 20 20">
      <g clipPath={`url(#${gradientIds[0]})`}>
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 0C4.47715 0 0 4.47715 0 10C0 13.009 1.32897 15.7076 3.43198 17.5408C2.89565 17.6815 2.5 18.1695 2.5 18.75C2.5 19.4404 3.05964 20 3.75 20H16.25C16.9404 20 17.5 19.4404 17.5 18.75C17.5 18.1695 17.1044 17.6815 16.568 17.5408C18.671 15.7076 20 13.009 20 10C20 4.47715 15.5228 0 10 0ZM7.5 11.25C6.80964 11.25 6.25 11.8096 6.25 12.5C6.25 13.1904 6.80964 13.75 7.5 13.75C8.19036 13.75 8.75 13.1904 8.75 12.5C8.75 11.8096 8.19036 11.25 7.5 11.25ZM11.25 10C11.25 10.6904 11.8096 11.25 12.5 11.25C13.1904 11.25 13.75 10.6904 13.75 10V8.75H15C15.6904 8.75 16.25 8.19036 16.25 7.5C16.25 6.80964 15.6904 6.25 15 6.25H13.75V5C13.75 4.30964 13.1904 3.75 12.5 3.75C11.8096 3.75 11.25 4.30964 11.25 5V6.25H10C9.30964 6.25 8.75 6.80964 8.75 7.5C8.75 8.19036 9.30964 8.75 10 8.75H11.25V10Z"
          fill={`url(#${gradientIds[1]})`}
        />
      </g>
      <defs>
        <linearGradient
          id={gradientIds[1]}
          x1="15"
          y1="2.84981e-08"
          x2="9.83514"
          y2="19.9573"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FF8066" />
          <stop offset="0.5" stop-color="#E666FF" />
          <stop offset="1" stop-color="#66B3FF" />
        </linearGradient>
        <clipPath id={gradientIds[0]}>
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
