import { useId, useState } from 'react';

import { ADS_CONSTANTS } from './AdsConstants';
import { useAppendScript } from './hooks';

const GlobalInlineProfileMiddleBanner = ({ userId }: { userId?: number }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const id = useId();

  useAppendScript({
    id,
    userId,
    onLoadImg: () => {
      setIsLoaded(true);
    },
  });

  return (
    <div id={id} className="relative h-24 mt-4 xl-msq:mt-5">
      {!isLoaded && <div className="skeleton-block w-full h-full" />}
      <ins
        className={ADS_CONSTANTS.className}
        data-cp-user-id={userId}
        data-key={ADS_CONSTANTS.globalInlineProfileMiddleBannerDataKey}
      />
    </div>
  );
};

export default GlobalInlineProfileMiddleBanner;
