'use client';

import React, { ReactNode } from 'react';

interface GoogleAdSenseProviderProps {
  children?: ReactNode;
}

export const GoogleAdSenseProvider = ({ children }: GoogleAdSenseProviderProps) => {
  return (
    <>
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2964868044958062"
        crossOrigin="anonymous"
      />
      {children}
    </>
  );
};
