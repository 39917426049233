import { AdsToggleKey } from '@api';

type AdsEnvironment = 'production' | 'development';

type TransformShowKey<K> = K extends `show${infer Rest}` ? Rest : never;

type AdsDataKeyConfig = {
  /**
   * `data-key` Specifies a unique identifier for the ad to load and display the corresponding ad content
   */
  [K in AdsToggleKey as `${Uncapitalize<TransformShowKey<K>>}DataKey`]: string;
};

type AdsConstants = {
  scriptUrl: string;
  /**
   * Is used to define the styling and corresponds to the specific ad placement
   */
  className: string;
} & Omit<AdsDataKeyConfig, 'storeMainInlineCarouselBannerDataKey'>;

type AdsConfig = Record<AdsEnvironment, AdsConstants>;

const DEFAULT_PRODUCTION_CLASSNAME = '421b37dd';
const DEFAULT_DEVELOPMENT_CLASSNAME = '37584a21';

const DEFAULT_PRODUCTION_URL = 'https://cdn77.aj2662.bid/519a9395.js';
const DEFAULT_DEVELOPMENT_URL = 'https://ads.epomtestsite.com/44693cee.js';

export const ADS_CONFIG: AdsConfig = {
  production: {
    scriptUrl: DEFAULT_PRODUCTION_URL,
    globalFloatingBottomRightBannerTextButtonDataKey: '7861141648cafc1c3b7351a06d144977',
    globalFloatingRightBannerDataKey: 'e9a756ccf5946f57bc6e60d49d2c256e',
    globalFloatingLeftBannerDataKey: '92611fd8fb95ccf9585d14a32c6175b3',
    globalStickyHeaderBannerTextButtonDataKey: '238c524afced3ed17f61eb493873e120',
    globalStickyBottomBannerMobileDataKey: 'e73490b008b8d10767d085114b9a238f',
    globalInlineProfileMiddleBannerDataKey: 'd6f91694023f1df4b7382684336c3917',
    storeSearchInlineSearchBottomBannerTextButtonDataKey: 'b06c9c155b89eaef8253b4c77f6cf648',
    storeAppInlineAppFormBottomRightDataKey: '66c9222edf6b92ad537e5e2592af0414',
    launchpadDashboardInlineTopRightBannerDataKey: 'fbd02caa8741c1ad1f42589a20c7ad96',
    launchpadMainInlineMiddleBannerDataKey: '12eb06b2d04d2bb756ec2fd6af282456',
    stakingMainInlineListBottomBannerDataKey: '5b0115639f6d1a99c93af8c83382d810',
    className: DEFAULT_PRODUCTION_CLASSNAME,
  },
  development: {
    scriptUrl: DEFAULT_DEVELOPMENT_URL,
    globalFloatingBottomRightBannerTextButtonDataKey: 'f75630ef8b0ef1e642969897c5011859',
    globalFloatingRightBannerDataKey: '90bae2057ca1f083d5d4e64bb4b14b3b',
    globalFloatingLeftBannerDataKey: 'f96ec8653830f3cb5a0098a078c260ce',
    globalStickyHeaderBannerTextButtonDataKey: '42dab5c5654db9e16066c460550b4aa2',
    globalStickyBottomBannerMobileDataKey: 'e6e0edd4a71c840fbd3fe6706fb4e324',
    globalInlineProfileMiddleBannerDataKey: '50c768e36d1e514e84939721ea289994',
    storeSearchInlineSearchBottomBannerTextButtonDataKey: '9cab951b08eb991b1b7802f6c692bbef',
    storeAppInlineAppFormBottomRightDataKey: '7c9f0a01efc223cc0321b243c9ebb090',
    launchpadDashboardInlineTopRightBannerDataKey: '224e4fb1d0cf962b72cf6fa13ab8b357',
    launchpadMainInlineMiddleBannerDataKey: 'a9300fef9794f4abc2011239bb9201b5',
    stakingMainInlineListBottomBannerDataKey: 'e465131fe4e4cb16d5c286d890909c3e',
    className: DEFAULT_DEVELOPMENT_CLASSNAME,
  },
};
