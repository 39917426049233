import Api from './ApiProvider';

export interface Messages {
  direction: 'incoming' | 'outgoing';
  type: 'CHOOSE_ONE' | 'TEXT';
  content: string;
  options?: string[];
  category?: string;
}

export interface Response {
  isAvailable: boolean;
  messages: Messages[];
}

export const getAiAssistantStatus = () => {
  return Api.get('v2/store/assistant/status').json<Response>();
};
