import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/next-virtual-a8c5709b84/0/cache/next-npm-14.2.20-903d2e69b2-a2e214762c.zip/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/next-virtual-a8c5709b84/0/cache/next-npm-14.2.20-903d2e69b2-a2e214762c.zip/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/next-virtual-a8c5709b84/0/cache/next-npm-14.2.20-903d2e69b2-a2e214762c.zip/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/next-virtual-a8c5709b84/0/cache/next-npm-14.2.20-903d2e69b2-a2e214762c.zip/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/next-virtual-a8c5709b84/0/cache/next-npm-14.2.20-903d2e69b2-a2e214762c.zip/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/builds/magic.ai/frontend/magicsquare/.yarn/__virtual__/next-virtual-a8c5709b84/0/cache/next-npm-14.2.20-903d2e69b2-a2e214762c.zip/node_modules/next/dist/client/components/render-from-template-context.js");
