import Markdown from 'markdown-to-jsx';

import ImageProxy from '@next-image-proxy';

import { File } from '@uikit/icons/File';

import { MagicAi } from '@ui-uikit/lib/components/icons/poly/MagicAi';

import { NavLayoutUser } from '../NavLayout/types';

export const AiMessage = ({ message }: { message: string }) => {
  return (
    <div className="flex justify-start items-start gap-2 w-full">
      <MagicAi className="icon-sm" />
      <Markdown className="text-caption-sm-a inner-html inner-chat-html">{message}</Markdown>
      {/* <span  dangerouslySetInnerHTML={{ __html:  }}></span> */}
    </div>
  );
};

export const UserMessage = ({ message, user }: { message: string; user?: NavLayoutUser }) => {
  const srcUserAvatar = user?.data.attributes.icon;

  return (
    <div className="flex justify-end items-start gap-2 w-full">
      <span
        className="text-caption-sm-a block px-3 py-2 bg-base-bg-secondary rounded-xl"
        dangerouslySetInnerHTML={{ __html: message }}
      ></span>
      <span className="w-5 h-5 min-w-5 rounded-full overflow-hidden">
        <ImageProxy
          alt="Users's avatar"
          src={srcUserAvatar}
          width={24}
          height={24}
          className="avatar-img"
        />
      </span>
    </div>
  );
};

export const HelpsButton = ({
  helpButtons = [],
  user,
  onButtonClick,
  messageIndex,
}: {
  helpButtons: string[];
  user?: NavLayoutUser;
  onButtonClick: (message: string, messageIndex: number) => void;
  messageIndex: number;
}) => {
  const srcUserAvatar = user?.data.attributes.icon;
  console.log(helpButtons);

  return (
    <div className="flex justify-end items-start gap-2 w-full">
      <div className="flex flex-wrap gap-2 justify-end">
        {helpButtons?.map((help) => (
          <button
            key={help}
            className="msq-btn msq-btn-lg msq-btn-outline text-caption-sm-a"
            onClick={() => onButtonClick(help, messageIndex)}
          >
            <File className="icon-xs text-base-text-tertiary" />
            {help}
          </button>
        ))}
      </div>
      <span className="w-5 h-5 min-w-5 rounded-full overflow-hidden">
        <ImageProxy
          alt="Users's avatar"
          src={srcUserAvatar}
          width={24}
          height={24}
          className="avatar-img"
        />
      </span>
    </div>
  );
};
