'use client';

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import classNames from 'classnames';
import { useAccount } from 'wagmi';

import { Modal, ModalCloseButton, ModalContent, ModalHeading } from '@uikit';

import { useGetSIWENonceMutation } from '@query';

import { SignType, WalletType } from '@api';

import ModalDescription from '@uikit/components/Modal/ModalDescription';
import Spinner from '@uikit/components/Spinner/Spinner';

import { useGlobalModalsController } from '@shared/common/providers/GlobalModalsProvider';
import useGlobalState from '@shared/common/providers/GlobalStateProvider';
import { namedWeb3Errors } from '@shared/constants';
import displayErrorToast, { normalizeError } from '@shared/helpers/displayErrorToast';
import displayWalletErrorToast from '@shared/helpers/displayWalletErrorToast';
import getChainRequirements from '@shared/helpers/web3/getChainRequirements';
import getSignTypeFlags from '@shared/helpers/web3/getSignTypeFlags';
import getWalletToastButtonClassName from '@shared/helpers/web3/getWalletToastButtonClassName';
import getWalletTypeFlags from '@shared/helpers/web3/getWalletTypeFlags';
import saveConnectionToCookies from '@shared/helpers/web3/saveConnectionToCookies';
import useWeb3 from '@shared/hooks/web3/useWeb3';

import { useAppKit } from '@reown/appkit/react';

const defaultSignType: SignType = 'SIWE';
const defaultWalletType: WalletType = 'WalletConnect';

const ConnectAndSignModal = () => {
  const { open } = useAppKit();
  const { requestSign } = useWeb3();
  const { address, isConnected } = useAccount();
  const [isSigning, setIsSigning] = useState(false);
  const { value: confirmData } = useGlobalState('connectAndSignConfirmCallback');
  const { mutateAsync: getSIWENonce, isPending: isFetchingNonce } = useGetSIWENonceMutation();
  const [nonce, setNonce] = useState<string>('');
  const connectAndSignModal = useGlobalModalsController('ConnectAndSign');
  const signType: SignType = confirmData?.signProps?.signType || defaultSignType;
  const walletType: WalletType = getWalletTypeFlags(
    confirmData?.signProps?.walletType || defaultWalletType,
  ).isMetamask
    ? 'Metamask'
    : 'WalletConnect';
  const { isWalletConnect } = getWalletTypeFlags(walletType);
  const isLoading = isSigning || isFetchingNonce;

  const prefetchNonce = async () => {
    let prefetchedNonce = '';

    try {
      prefetchedNonce = await getSIWENonce();
    } catch (error) {
      displayErrorToast({ error });
    }

    setNonce(prefetchedNonce);

    return prefetchedNonce;
  };

  // Pre-fetch random nonce when button is rendered
  // to ensure deep linking works for WalletConnect
  // users on iOS when signing the SIWE message
  useEffect(() => {
    const { isSIWE } = getSignTypeFlags(signType);

    if (connectAndSignModal.isOpen && !nonce && isSIWE) {
      prefetchNonce();
    }
  }, [connectAndSignModal.isOpen]);

  const isWalletConnected = address && isConnected;

  const handleConnectClick = async () => {
    try {
      if (isWalletConnect) {
        return await open();
      }

      throw new Error('There is no connector for that wallet.');
    } catch (error) {
      displayWalletErrorToast({ error });
    }
  };

  const handleSignClick = async () => {
    try {
      confirmData?.setIsLoading?.(true);
      setIsSigning(true);

      const { isSIWE } = getSignTypeFlags(signType);

      if (!nonce && isSIWE) {
        const newNonce = await prefetchNonce();

        if (!newNonce) {
          throw new Error(namedWeb3Errors.SIGNATURE_VERIFICATION_HAS_FAILED);
        }

        setNonce(newNonce);
      }

      if (!isWalletConnected) {
        toast.info('Wallet not connected. Please connect your wallet and try again.');
        return;
      }

      const signResults = await requestSign({
        ...confirmData.signProps,
        nonce,
        signType,
        walletType,
        skipConnectStep: true,
      });

      if (signResults) {
        saveConnectionToCookies(signResults.address, signResults.walletType);
      }

      await confirmData.confirmCallback(signResults);

      if (signResults) {
        connectAndSignModal.close();
        setNonce('');
      }

      setIsSigning(false);
    } catch (error) {
      confirmData?.setIsLoading?.(false);
      setIsSigning(false);

      const normalizedError: Error = normalizeError(error);

      if (normalizedError.message === namedWeb3Errors.SIGNATURE_VERIFICATION_HAS_FAILED) {
        const newNonce = await prefetchNonce();

        setNonce(newNonce || '');

        displayErrorToast({ error: `Please try again${newNonce ? '' : ' later'}.` });
      } else {
        displayWalletErrorToast({ error });
      }
    }
  };

  const requirements = getChainRequirements();

  return (
    <Modal {...connectAndSignModal.props}>
      <ModalContent className="w-full min-w-[280px] max-w-[500px] flex flex-col p-0 max-sm:mb-0 rounded-xl">
        <ModalCloseButton className="m-4" />

        <div className="flex flex-col px-6 sm:px-10 pb-7 pt-4">
          <ModalHeading className="mb-7">
            {isWalletConnected ? confirmData.confirmStepTitle : confirmData.connectStepTitle}
          </ModalHeading>

          {confirmData.signProps?.shouldVerifyChain && (
            <ModalDescription>{requirements.chain}</ModalDescription>
          )}

          <button
            className={classNames(
              'button button-lg button-solid-primary gap-3',
              getWalletToastButtonClassName(walletType),
              { '!button-disabled': isLoading },
            )}
            onClick={isWalletConnected ? handleSignClick : handleConnectClick}
            disabled={isLoading}
          >
            {isLoading && <Spinner size="sm" color="light" />}
            {isWalletConnected ? confirmData.confirmButtonText : 'Connect your Wallet'}
          </button>

          {isWalletConnected && isWalletConnect && (
            <button
              className="button button-lg button-ghost w-full mt-2 sm:mt-3"
              onClick={() => open()}
            >
              My Wallet
            </button>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
};

export default ConnectAndSignModal;
