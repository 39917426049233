import { useMemo } from 'react';

import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { UndefinedInitialDataInfiniteOptions } from '@tanstack/react-query/src/infiniteQueryOptions';
import { UndefinedInitialDataOptions } from '@tanstack/react-query/src/queryOptions';

import { format, subDays } from 'date-fns';
import { Options } from 'ky';

import {
  ClaimRewardsResponse,
  claimSelfKarmaReward,
  claimTokenWithGasStation,
  DEFAULT_KARMA_HISTORY_LIMIT,
  getActionsByDate,
  getCurrentUserKarmaActions,
  getInvitedUserList,
  GetKarmaActionHistoryParams,
  getKarmaHistory,
  getLeaderboard,
  getMonthKarmaUserHistory,
  getReferralStats,
  getRewardGuides,
  getRewardSetting,
  getSelfKarma,
  getSIWENonce,
  getUserBalances,
  getUserSqrvBalance,
  getWeekKarmaUserHistory,
  KarmaPeriodType,
  KarmaRewardSettings,
  RewardGuides,
  RewardGuidesWithKarmaEarnedPoints,
} from '@api';

import { ApiOptions } from '@shared/api/ApiProvider';
import { useAuth } from '@shared/common/providers/AuthProvider';
import { KARMA_HISTORY_PERIOD } from '@shared/constants';

import { AUTH_USER_QUERY_KEY } from './keys';

const fetchKarmaWithParams = (
  limit: number,
  pageParam = 0,
  fromDate?: Date,
  date?: Date,
  filter?: string,
): Promise<any> => {
  let dateResult: string | null = null;

  switch (filter) {
    case KARMA_HISTORY_PERIOD.TODAY:
      dateResult = format(new Date(), 'yyyy-MM-dd');
      break;

    case KARMA_HISTORY_PERIOD.YESTERDAY:
      dateResult = format(subDays(new Date(), 1), 'yyyy-MM-dd');
      break;

    case KARMA_HISTORY_PERIOD.WEEK:
      return getWeekKarmaUserHistory({ searchParams: { limit, offset: limit * pageParam } });

    case KARMA_HISTORY_PERIOD.MONTH:
      return getMonthKarmaUserHistory({ searchParams: { limit, offset: limit * pageParam } });

    default:
      break;
  }

  return getKarmaHistory({
    searchParams: {
      limit,
      filter,
      offset: limit * pageParam,
      ...(date &&
        fromDate && { date: format(date, 'yyyy-MM-dd'), fromDate: format(fromDate, 'yyyy-MM-dd') }),
      ...(dateResult && { date: dateResult }),
    },
  });
};

export const SELF_KARMA_DEFAULT_PARAMS: KarmaPeriodType = 'daily';

export const karmaQueryKeys = {
  referralStats: [AUTH_USER_QUERY_KEY, 'referralStats'],
  leaderboard: (limit: number, period?: string) => ['karmaLeaderboard', limit, period],
  invited: (limit: number) => [AUTH_USER_QUERY_KEY, 'invitedUsers', limit],
  selfKarma: (period: KarmaPeriodType) => [AUTH_USER_QUERY_KEY, 'selfKarma', period],
  rewardSetting: ['rewardSetting'],
  karmaHistory: (params?: GetKarmaActionHistoryParams) => [
    AUTH_USER_QUERY_KEY,
    'selfKarmaActionHistory',
    {
      limit: DEFAULT_KARMA_HISTORY_LIMIT,
      date: '',
      ...params,
    },
  ],
  currentUserKarma: ['currentUserKarma'],
  selfKarmaActions: [AUTH_USER_QUERY_KEY, 'selfKarmaActions'],
  karmaGuides: ['karmaGuides'],
  selfKarmaActionHistoryByDate: (params: GetKarmaActionHistoryParams) => [
    AUTH_USER_QUERY_KEY,
    'selfKarmaActionHistoryByDate',
    params,
  ],
  userBalances: (userId?: number) => [AUTH_USER_QUERY_KEY, 'userBalances', userId],
  userSqrvBalance: ['userSqrvBalance'],
};

export const prefetchReferralStatsQuery = async (clientQuery: QueryClient, params: Options) => {
  await clientQuery.prefetchQuery({
    queryKey: karmaQueryKeys.referralStats,
    queryFn: () => getReferralStats(params),
  });

  return clientQuery.getQueryData<Awaited<ReturnType<typeof getReferralStats>>>(
    karmaQueryKeys.referralStats,
  );
};

export const prefetchInvitedUserQuery = (
  clientQuery: QueryClient,
  params?: ApiOptions<{ searchParams?: { limit?: number } }>,
) => {
  return clientQuery.prefetchInfiniteQuery({
    queryKey: karmaQueryKeys.invited(params?.searchParams?.limit || 20),
    queryFn: () => getInvitedUserList(params),
    initialPageParam: 0,
  });
};

export const prefetchLeaderboardQuery = (
  clientQuery: QueryClient,
  params?: ApiOptions<{ searchParams?: { limit: number; period: string } }>,
) => {
  return clientQuery.prefetchInfiniteQuery({
    queryKey: karmaQueryKeys.leaderboard(
      params?.searchParams?.limit || 20,
      params?.searchParams?.period || 'daily',
    ),
    queryFn: () => getLeaderboard(params),
    initialPageParam: 0,
  });
};

export const prefetchSelfKarmaQuery = (
  clientQuery: QueryClient,
  params: ApiOptions<{ searchParams?: { period: KarmaPeriodType } }>,
) => {
  return clientQuery.prefetchQuery({
    queryKey: karmaQueryKeys.selfKarma(params?.searchParams?.period || SELF_KARMA_DEFAULT_PARAMS),
    queryFn: () => getSelfKarma(params),
  });
};

export const prefetchRewardSettingQuery = (clientQuery: QueryClient, params: Options) => {
  return clientQuery.prefetchQuery({
    queryKey: karmaQueryKeys.rewardSetting,
    queryFn: () => getRewardSetting(params),
  });
};

export const prefetchKarmaHistoryQuery = (
  clientQuery: QueryClient,
  options: Parameters<typeof getKarmaHistory>[0],
) => {
  return clientQuery.prefetchQuery({
    queryKey: karmaQueryKeys.karmaHistory(options?.searchParams),
    queryFn: () => getKarmaHistory(options),
  });
};

export const prefetchUserKarmaActionsQuery = (
  clientQuery: QueryClient,
  options: Parameters<typeof getCurrentUserKarmaActions>[0],
) => {
  return clientQuery.prefetchQuery({
    queryKey: karmaQueryKeys.currentUserKarma,
    queryFn: () => getCurrentUserKarmaActions(options),
  });
};

export const prefetchRewardGuidesQuery = async (
  clientQuery: QueryClient,
  params: ApiOptions<{ searchParams?: { limit?: number } }>,
) => {
  await clientQuery.prefetchQuery({
    queryKey: karmaQueryKeys.karmaGuides,
    queryFn: () => getRewardGuides(params),
  });

  return clientQuery.getQueryData<RewardGuides>(karmaQueryKeys.karmaGuides);
};

export const prefetchRewardGuidesWithSelfProgress = async (
  clientQuery: QueryClient,
  params: ApiOptions<{ searchParams?: { actions?: string; limit?: number } }>,
) => {
  const rewardGuides = await prefetchRewardGuidesQuery(clientQuery, params);
  const actions = rewardGuides?.data?.map(({ id }) => id) || [];
  await prefetchUserKarmaActionsQuery(clientQuery, {
    ...params,
    searchParams: {
      actions: JSON.stringify(actions),
      ...params.searchParams,
    },
  });
};

export const useSelfReferralStatsQuery = ({
  params,
  options,
}: {
  params?: Options;
  options?: {
    enabled?: boolean;
  };
} = {}) => {
  return useQuery({
    queryKey: karmaQueryKeys.referralStats,
    queryFn: () => getReferralStats(params),
    ...options,
  });
};

export const useSelfKarmaQuery = (
  params: ApiOptions<{ searchParams?: { period: KarmaPeriodType } }>,
  options?: Pick<
    UndefinedInitialDataOptions<Awaited<ReturnType<typeof getSelfKarma>>, unknown, unknown, any>,
    'enabled'
  >,
) => {
  // TODO: research the case when for some reason params is a string e.g. 'daily' instead of object
  // Safe check to ensure params is an object, otherwise fallback to a safe object
  const safeParams =
    typeof params === 'object' && params !== null
      ? params
      : { searchParams: { period: SELF_KARMA_DEFAULT_PARAMS } };

  const safeOptions = options || {};

  const queryKey = karmaQueryKeys.selfKarma(
    safeParams.searchParams?.period || SELF_KARMA_DEFAULT_PARAMS,
  );

  const query = useQuery({
    queryKey,
    queryFn: () => getSelfKarma(safeParams),
    staleTime: 500,
    ...safeOptions,
  });

  return query;
};

export const useKarmaRewardSettingsQuery = (
  options?: Options,
  queryOptions?: UseQueryOptions<KarmaRewardSettings>,
) => {
  return useQuery({
    queryKey: karmaQueryKeys.rewardSetting,
    queryFn: () => getRewardSetting(options),
    ...queryOptions,
  });
};

export const useKarmaHistoryQuery = ({
  limit = 6,
  params,
  options,
}: {
  limit?: number;
  params?: GetKarmaActionHistoryParams;
  options?: Pick<
    UndefinedInitialDataInfiniteOptions<
      Awaited<ReturnType<typeof getKarmaHistory>>,
      unknown,
      unknown,
      any,
      number
    >,
    'enabled'
  >;
}) => {
  return useInfiniteQuery({
    queryKey: karmaQueryKeys.karmaHistory(params),
    queryFn: ({ pageParam }) =>
      getKarmaHistory({
        searchParams: { limit, offset: pageParam },
      }),
    getNextPageParam: (lastPage, pages = []) =>
      lastPage.length === limit ? limit * pages.length : undefined,
    initialPageParam: 0,
    staleTime: 0,
    ...options,
  });
};

export const useUserKarmaActionsQuery = (
  { limit }: { limit?: number },
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getCurrentUserKarmaActions>>,
      unknown,
      unknown,
      any
    >,
    'enabled' | 'staleTime'
  >,
) => {
  return useQuery({
    queryKey: karmaQueryKeys.selfKarmaActions,
    queryFn: () =>
      getCurrentUserKarmaActions({
        searchParams: {
          limit,
        },
      }),
    ...options,
  });
};

export const useRewardGuidesQuery = (
  options?: ApiOptions<{ searchParams?: { limit?: number } }>,
) => {
  return useQuery({
    queryKey: karmaQueryKeys.karmaGuides,
    queryFn: () => getRewardGuides(options),
  });
};

export const useAllKarmaActionsWithCurrentUserPointsQuery = ({
  limit,
}: {
  limit?: number;
}): {
  data: RewardGuidesWithKarmaEarnedPoints[];
  isLoading: boolean;
  limit?: number;
} => {
  const { data: allKarmaActions, isPending: areAllKarmaActionsLoading } = useRewardGuidesQuery({
    searchParams: { limit },
  });
  const actions: string[] = useMemo(
    () => (allKarmaActions ? allKarmaActions?.data?.map(({ id }) => id) : []),
    [allKarmaActions],
  );
  // TODO: we need to get rid of this second call and get all data from useRewardGuidesQuery
  const {
    data: currentUserEarnedKarmaByActions,
    isPending: areCurrentUserEarnedKarmaByActionsLoading,
  } = useUserKarmaActionsQuery(
    {
      limit,
    },
    {
      enabled: Boolean(actions?.length),
      staleTime: 15000,
    },
  );
  const data = useMemo(() => {
    if (!allKarmaActions?.data || !currentUserEarnedKarmaByActions) {
      return [];
    }

    return allKarmaActions.data.map((action) => ({
      ...action,
      attributes: {
        ...action.attributes,
        earnedPoints: currentUserEarnedKarmaByActions[action.id],
      },
    }));
  }, [allKarmaActions, currentUserEarnedKarmaByActions]);
  const isLoading = areAllKarmaActionsLoading || areCurrentUserEarnedKarmaByActionsLoading;

  return {
    data,
    isLoading,
  };
};

export const useSelfKarmaActionHistoryByDateQuery = (
  params?: GetKarmaActionHistoryParams,
  options?: Pick<
    UndefinedInitialDataOptions<
      Awaited<ReturnType<typeof getActionsByDate>>,
      unknown,
      unknown,
      any
    >,
    'enabled' | 'staleTime'
  >,
) => {
  const searchParams = {
    limit: 20,
    ...params,
  };

  return useQuery({
    queryKey: karmaQueryKeys.selfKarmaActionHistoryByDate(searchParams),
    queryFn: () =>
      getActionsByDate({
        searchParams,
      }),
    ...options,
  });
};

export const useInvitedUsersInfiniteQuery = ({
  limit = 20,
  enabled,
}: {
  limit?: number;
  enabled?: boolean;
} = {}) => {
  return useInfiniteQuery({
    queryKey: karmaQueryKeys.invited(limit),
    queryFn: ({ pageParam }) => getInvitedUserList({ searchParams: { limit, offset: pageParam } }),
    getNextPageParam: (lastPage, pages = []) =>
      lastPage?.length === limit ? limit * pages.length : undefined,
    initialPageParam: 0,
    enabled,
  });
};

export const useKarmaLeaderboardInfiniteQuery = ({
  limit = 20,
  period = 'daily',
  options,
}: {
  limit?: number;
  period?: string;
  options?: Pick<
    UndefinedInitialDataInfiniteOptions<
      Awaited<ReturnType<typeof getLeaderboard>>,
      unknown,
      unknown,
      any,
      number
    >,
    'enabled'
  >;
} = {}) => {
  return useInfiniteQuery({
    queryKey: karmaQueryKeys.leaderboard(limit, period),
    queryFn: ({ pageParam }) =>
      getLeaderboard({ searchParams: { limit, offset: pageParam, period } }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages = []) =>
      lastPage.rows.length === limit ? limit * pages.length : undefined,
    staleTime: 0,
    ...options,
  });
};

export const useKarmaHistoryInfiniteQuery = ({
  fromDate,
  date,
  limit = 20,
  options = {},
  filter,
}: {
  fromDate?: Date;
  date?: Date;
  limit?: number;
  options?: Pick<
    UndefinedInitialDataInfiniteOptions<
      Awaited<ReturnType<typeof fetchKarmaWithParams>>,
      unknown,
      unknown,
      any,
      number
    >,
    'enabled' | 'placeholderData'
  >;
  filter?: string;
}) => {
  return useInfiniteQuery({
    queryKey: karmaQueryKeys.karmaHistory({
      limit,
      date: date ? format(date, 'yyyy-MM-dd') : '',
      fromDate: fromDate ? format(fromDate, 'yyyy-MM-dd') : '',
      filter: filter || '',
    }),
    queryFn: ({ pageParam }) => fetchKarmaWithParams(limit, pageParam, fromDate, date, filter),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages = []) => {
      return lastPage?.rows?.length === limit ? pages.length : undefined;
    },
    staleTime: 0,
    ...options,
  });
};

export const useUserBalancesQuery = (
  userId?: number,
  options?: {
    enabled?: boolean;
  },
) => {
  return useQuery({
    queryKey: karmaQueryKeys.userBalances(userId),
    queryFn: () => getUserBalances({ searchParams: { userId } }),
    staleTime: 0,
    ...options,
  });
};

export const useClaimSelfKarmaRewardMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (options: Parameters<typeof claimSelfKarmaReward>[0]['json']) => {
      const karmaReward: ClaimRewardsResponse = await claimSelfKarmaReward({ json: options });
      const userKarma = await getSelfKarma();

      return { karmaReward, userKarma };
    },
    onSuccess({ userKarma }) {
      const queryKey = karmaQueryKeys.selfKarma(SELF_KARMA_DEFAULT_PARAMS);
      queryClient.setQueryData(queryKey, userKarma);
    },
  });
};

export const useGetSIWENonceMutation = () => {
  return useMutation({ mutationFn: () => getSIWENonce({}) });
};

export const useClaimWithGasStationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (options: Parameters<typeof claimTokenWithGasStation>[0]['json']) =>
      claimTokenWithGasStation({ json: options }),
    onSuccess() {
      const queryKey = karmaQueryKeys.selfKarma(SELF_KARMA_DEFAULT_PARAMS);
      queryClient.invalidateQueries({ queryKey: [queryKey] });
    },
  });
};

export const useUserSqrvBalance = () => {
  const { userEvmWallet } = useAuth();

  return useQuery({
    queryKey: [karmaQueryKeys.userSqrvBalance, userEvmWallet?.attributes.address],
    enabled: !!userEvmWallet?.attributes.address,
    queryFn: () => getUserSqrvBalance(),
  });
};
