import { Cross } from '@ui/uikit/components/icons/mono';

export const AdCloseButton = ({ onClick }: { onClick?: () => void }) => {
  return (
    <button
      className="msq-btn msq-btn-overlay w-6 h-6 rounded-md absolute right-3 top-3 z-10"
      type="button"
      onClick={onClick}
    >
      <Cross className="icon size-4" />
    </button>
  );
};
