'use client';

import {
  cloneElement,
  ComponentPropsWithoutRef,
  ComponentType,
  isValidElement,
  ReactNode,
  useMemo,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';
import DOMPurify from 'isomorphic-dompurify';

import Close from '@uikit/icons/Close';
import InfoCircle from '@uikit/icons/InfoCircle';

import type { Placement } from '@floating-ui/react';
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  FloatingPortal,
  offset,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';

export interface TooltipContent {
  title?: string;
  content: ReactNode;
}

const isValidContentConfig = (content: unknown): content is TooltipContent =>
  typeof content === 'object' && content !== null && 'title' in content && 'content' in content;

export interface TooltipProps extends Omit<ComponentPropsWithoutRef<'div'>, 'content'> {
  disabled?: boolean;
  content: TooltipContent | TooltipContent[] | ReactNode;
  label?: ReactNode | ComponentType;
  initialOpen?: boolean;
  placement?: Placement;
  isHTML?: boolean;
  iconClass?: string;
  tooltipClass?: string;
  trigger?: ReactNode;
}

const ContentTooltip = ({
  content = '',
  isHTML = true,
  isNeedPadding = false,
}: {
  content: ReactNode;
  isHTML: boolean;
  isNeedPadding?: boolean;
}) => {
  if (isHTML && typeof content === 'string') {
    return (
      <div
        className={classNames('text-sm font-normal inner-html	text-neutral-400', {
          'pb-4': isNeedPadding,
        })}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
      />
    );
  }

  return (
    <div className={classNames('text-sm font-normal	text-neutral-400', { 'pb-4': isNeedPadding })}>
      {content}
    </div>
  );
};

interface ListTooltipProps {
  contents: TooltipContent[];
  isHTML?: boolean;
}

const ListTooltip = ({ contents = [], isHTML = true }: ListTooltipProps): JSX.Element => {
  return (
    <>
      {contents.map(({ title, content }, index) => (
        <div key={index}>
          {title && (
            <h5
              className={classNames('pb-4 text-base	font-semibold', {
                'text-primary-500': contents.length > 1,
              })}
            >
              {title}
            </h5>
          )}
          <ContentTooltip content={content} isHTML={isHTML} isNeedPadding={!!title} />
        </div>
      ))}
    </>
  );
};

const TooltipInfoIcon = () => {
  return <InfoCircle className="icon-[0.875rem] text-neutral-400  hover:text-secondary" />;
};

const Tooltip = ({
  disabled,
  content,
  label: TooltipLabel = TooltipInfoIcon,
  initialOpen = false,
  placement = 'bottom',
  isHTML = true,
  iconClass = '',
  tooltipClass = '',
  trigger,
}: TooltipProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const arrowRef = useRef(null);
  const resultContent = useMemo(() => {
    if (isValidContentConfig(content)) {
      return [content];
    }

    return content;
  }, [content]);
  const { refs, floatingStyles, context } = useFloating({
    open: disabled ? false : isOpen,
    onOpenChange: setIsOpen,
    placement,
    middleware: [
      offset(5),
      flip({ fallbackAxisSideDirection: 'start', padding: 5 }),
      shift({ padding: 5 }),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });
  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    initial: {
      opacity: 0,
      transform: 'scale(0.9)',
    },
    close: {
      opacity: 0,
      transform: 'scale(0.9)',
    },
    open: {
      opacity: 1,
      transform: 'scale(1)',
    },
  });
  const hover = useHover(context, {
    move: false,
    handleClose: safePolygon(),
    delay: {
      open: 0,
      close: 200,
    },
  });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: 'tooltip' });
  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role]);

  const closeTooltip = () => {
    setIsOpen(false);
  };

  return (
    <>
      {trigger && isValidElement(trigger) ? (
        cloneElement(
          trigger,
          getReferenceProps({
            ref: refs.setReference,
            ...trigger.props,
          }),
        )
      ) : (
        <div
          className={classNames('tooltip-button inline-flex cursor-pointer', iconClass)}
          ref={refs.setReference}
          {...getReferenceProps()}
        >
          {typeof TooltipLabel === 'function' ? <TooltipLabel /> : TooltipLabel}
        </div>
      )}
      <FloatingPortal>
        {isMounted && (
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            className={classNames(
              'tooltip-content z-50 max-h-[90vh]',
              'max-w-[300px]',
              tooltipClass,
            )}
          >
            <div
              className="flex flex-col rounded-lg bg-neutral-50 px-4 py-2 text-center shadow-main md:max-w-screen-sm md:overflow-y-visible md:pt-2 md:text-left"
              style={transitionStyles}
            >
              <button className="md:hidden self-end" onClick={closeTooltip}>
                <Close className="icon-[0.875rem] text-secondary" />
              </button>

              <FloatingArrow ref={arrowRef} context={context} fill="white" />

              {Array.isArray(resultContent) ? (
                <ListTooltip contents={resultContent} isHTML={isHTML} />
              ) : (
                <ContentTooltip content={resultContent} isHTML={isHTML} isNeedPadding={false} />
              )}
            </div>
          </div>
        )}
      </FloatingPortal>
    </>
  );
};

export default Tooltip;
