import React, { forwardRef, ReactNode, Ref } from 'react';

import classNames from 'classnames';

import * as Label from '@radix-ui/react-label';

import { InfoTooltip, TooltipContentProps } from '../Tooltip';

export interface FormControlProps {
  htmlFor?: string;
  label?: ReactNode;
  hint?: ReactNode;
  isError?: boolean;
  visuallyRequired?: boolean;
  children?: ReactNode;
  className?: string;
  tooltip?: TooltipContentProps;
  dataQa?: string;
}

const FormControl = forwardRef(
  (
    {
      label,
      htmlFor,
      isError,
      children,
      hint,
      className,
      tooltip,
      visuallyRequired,
      dataQa,
    }: FormControlProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    return (
      <div className={classNames('flex flex-col gap-2', className)} ref={ref}>
        {(label || tooltip || visuallyRequired) && (
          <div className="flex gap-1">
            <Label.Root htmlFor={htmlFor} className="text-caption-sm-strong-d" data-qa={dataQa}>
              {label}

              {visuallyRequired && (
                <span aria-label="required" className="text-base-text-pink inline-block ml-1">
                  *
                </span>
              )}
            </Label.Root>

            {tooltip && <InfoTooltip {...tooltip} triggerClassName="ml-auto tooltip-button-md" />}
          </div>
        )}

        <div className="flex flex-col gap-1">
          {children}

          {hint && (
            <span
              data-qa="profile-note-label"
              className={classNames('text-base-text-quaternary text-caption-xs-d', {
                'text-base-text-red': isError,
              })}
            >
              {hint}
            </span>
          )}
        </div>
      </div>
    );
  },
);

FormControl.displayName = 'FormControl';

export { FormControl };
