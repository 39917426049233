import { Fragment, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import ImageProxy from '@next-image-proxy';

import { useAiAssistantStatusQuery } from '@query';

import { Messages } from '@api';

import { useSocket } from '@shared/common/providers/SocketProvider';

import { FormInput } from '@ui-uikit/lib/components/common/FormInput';
import { ArrowRight } from '@ui-uikit/lib/components/icons/mono';
import { MagicAi } from '@ui-uikit/lib/components/icons/poly/MagicAi';

import { useNavLayout } from '../NavLayout';
import { AiMessage, HelpsButton, UserMessage } from './Messages';

const defaultValues = {
  message: '',
};

interface FormData {
  message: string;
}

const Chat = () => {
  const {
    handleSubmit,
    control,
    reset: resetForm,
  } = useForm<FormData>({
    defaultValues,
    mode: 'onSubmit',
  });

  const { user, onLogin } = useNavLayout();

  const [category, setCategory] = useState('Main menu');

  const scrollRef = useRef<HTMLDivElement>(null);

  const { socket } = useSocket();

  const { data, isPending } = useAiAssistantStatusQuery({ enabled: !!user });

  const [messages, setMessage] = useState<Messages[]>();
  const [isSendingMessageDisabele, setIsSendingMessageDisabele] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);

  useEffect(() => {
    if (!data?.isAvailable) {
      return;
    }

    setIsSendingMessageDisabele(data?.messages[data?.messages.length - 1]?.type === 'CHOOSE_ONE');
    setCategory(data?.messages[data?.messages.length - 1]?.category || 'Main menu');
    setMessage([...(data?.messages || [])]);

    socket?.on('assistant-message', ({ message }: { message: Messages }) => {
      if (message.category) {
        setCategory(message.category);
      }

      setIsSendingMessageDisabele(message.type === 'CHOOSE_ONE');
      setIsSendingMessage(false);
      setMessage((oldData) => [
        ...(oldData || []),
        {
          direction: 'incoming',
          type: message.type,
          content: message.content,
          options: message.options || [],
        },
      ]);
    });

    return () => {
      socket?.off('assistant-message');
    };
  }, [data]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [messages]);

  const userSendMessage = (message: string) => {
    setIsSendingMessageDisabele(true);
    setIsSendingMessage(true);
    setMessage((oldData) => [
      ...(oldData || []),
      {
        direction: 'outgoing',
        type: 'TEXT',
        content: message,
      },
    ]);
    socket?.emit('call', 'assistant', { message: { content: message }, context: {} });
    resetForm();
  };

  const clickButton = (message: string, messageIndex: number) => {
    if (messageIndex >= 0) {
      setMessage((oldData) => {
        const newArr = [...(oldData || [])];
        newArr.splice(messageIndex, 1);
        return oldData;
      });
    }

    userSendMessage(message);
  };

  const handleSubmitForm = handleSubmit(async (formData) => {
    userSendMessage(formData.message);
  });

  if (!user) {
    return (
      <div className="w-full flex flex-col justify-center items-center gap-6 text-center h-full">
        <span className="msq-btn msq-btn-icon-lg msq-btn-brand-gradient rounded-full">
          <MagicAi className="icon-xs" />
        </span>
        <span className="text-caption-md-a">
          Hello! I'm Mystic, your AI assistant <br />
          for navigating the Web3 world.
        </span>
        <span className="text-caption-md-a">Connect your wallet to get started.</span>
        <button className="msq-btn msq-btn-lg msq-btn-primary" onClick={onLogin}>
          Connect
        </button>
      </div>
    );
  }

  if (isPending) {
    return (
      <div className="flex justify-center items-center h-full">
        <h2 className="text-h2-a">Loading...</h2>
      </div>
    );
  }

  if (!isPending && !data?.isAvailable) {
    return (
      <div className="flex justify-center items-center h-full">
        <h2 className="text-h2-a">Chat is not avalible</h2>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col justify-between h-full">
      <div className="p-3 border-b border-base-border-light">
        <p className="text-caption-sm-a">{category}</p>
      </div>
      <div className="flex flex-col justify-end items-center w-full max-h-full p-6 overflow-hidden">
        <div className="overflow-y-auto max-h-full" ref={scrollRef}>
          <div className="flex flex-col justify-end gap-2 w-full">
            {messages?.map((message, index) => {
              if (message.type === 'CHOOSE_ONE') {
                return (
                  <Fragment key={`${message.content}_${index}`}>
                    <AiMessage message={message.content} />
                    {index === messages.length - 1 && (
                      <HelpsButton
                        user={user}
                        helpButtons={message?.options || []}
                        onButtonClick={clickButton}
                        messageIndex={0}
                      />
                    )}
                  </Fragment>
                );
              }

              if (message.type === 'TEXT') {
                return (
                  <Fragment key={`${message.content}_${index}`}>
                    {message.direction === 'outgoing' && (
                      <UserMessage message={message.content} user={user} />
                    )}
                    {message.direction === 'incoming' && <AiMessage message={message.content} />}
                  </Fragment>
                );
              }
            })}

            {category !== 'Main menu' && (
              <HelpsButton
                user={user}
                helpButtons={['Main menu']}
                onButtonClick={clickButton}
                messageIndex={-1}
              />
            )}
            {isSendingMessage && (
              <div className="flex justify-start items-start gap-2 w-full animate-pulse">
                <MagicAi className="icon-sm" />
                <span className="text-base-text-tertiary text-caption-sm-a">
                  Mystic is typing...
                </span>
              </div>
            )}
          </div>
        </div>
        <form
          onSubmit={handleSubmitForm}
          className="w-full flex justify-end gap-2 items-start pt-2"
        >
          <Controller
            control={control}
            name="message"
            rules={{
              required: 'This is required.',
              maxLength: {
                value: 500,
                message: 'Please input less than 500 symbol',
              },
              minLength: {
                value: 2,
                message: 'Please input more than 2 symbol',
              },
            }}
            disabled={isSendingMessageDisabele}
            render={({ field, fieldState }) => {
              return (
                <>
                  <FormInput
                    isError={!!fieldState.error}
                    hint={fieldState?.error?.message || ''}
                    placeholder="Message Mystic"
                    className="w-full"
                    {...field}
                  />
                </>
              );
            }}
          />

          <button type="submit" className="msq-btn msq-btn-icon-lg msq-btn-secondary">
            <ArrowRight className="icon-xs" />
          </button>
          <span className="min-w-6 w-6 h-6 rounded-full overflow-hidden">
            <ImageProxy
              alt="Users's avatar"
              src={user?.data?.attributes?.icon}
              width={24}
              height={24}
              className="avatar-img"
            />
          </span>
        </form>
      </div>
    </div>
  );
};

export default Chat;
