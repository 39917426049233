import * as Dialog from '@radix-ui/react-dialog';
import { CrossLg } from '@ui/uikit/components/icons/mono/CrossLg';

import Chat from './Chat';

const ChatDialog = ({ ...props }: Dialog.DialogProps) => {
  return (
    <Dialog.Root {...props}>
      <Dialog.Portal>
        <Dialog.Overlay
          forceMount
          className="msq-dialog msq-dialog-animate msq-dialog-mobile-menu-m xl-msq:msq-dialog-mobile-menu-d bg-transparent"
        >
          <Dialog.Content
            forceMount
            aria-describedby={undefined}
            className="msq-dialog-content sm-msq:msq-dialog-content-sm sm-msq:msq-dialog-content-right xl-msq:max-w-[600px] z-50 border border-base-border-light block h-full"
          >
            <div className="grid grid-cols-[1fr_50px] h-full">
              <div className="border-r w-full border-base-border-light h-full overflow-hidden">
                <Chat />
              </div>
              <div className="flex flex-col p-2 gap-2">
                <Dialog.Close asChild>
                  <button className="msq-btn msq-btn-icon-md msq-dialog-icon-button mb-2">
                    <CrossLg className="msq-btn msq-btn-icon-child" />
                  </button>
                </Dialog.Close>
                {/* <div className="msq-btn msq-btn-icon-md msq-dialog-icon-button bg-base-bg-tertiary">
                  1
                </div>
                <div className="msq-btn msq-btn-icon-md msq-dialog-icon-button bg-base-bg-tertiary">
                  2
                </div>
                <div className="msq-btn msq-btn-icon-md msq-dialog-icon-button bg-base-bg-tertiary">
                  3
                </div> */}
              </div>
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ChatDialog;
