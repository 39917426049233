import { useId, useState } from 'react';

import classNames from 'classnames';

import { AdCloseButton } from './AdCloseButton';
import { ADS_CONSTANTS } from './AdsConstants';
import { useAppendScript } from './hooks';

const GlobalFloatingSideBanner = ({
  className,
  userId,
  position = 'right',
}: {
  className?: string;
  userId?: number;
  position?: 'left' | 'right';
}) => {
  const id = useId();

  const [showAd, setIsShowAd] = useState(true);

  useAppendScript({ id, userId });

  const handleClick = () => {
    setIsShowAd(false);
  };

  if (!showAd) {
    return;
  }

  return (
    <div
      className={classNames(
        `fixed rounded-lg overflow-hidden z-10 shadow-xl bg-base-bg max-side-ad-msq:hidden ${position}-4`,
        className,
      )}
    >
      <div className="relative w-full h-full" id={id}>
        <AdCloseButton onClick={handleClick} />
        <ins
          className={ADS_CONSTANTS.className}
          data-cp-user-id={userId}
          data-key={
            position === 'right'
              ? ADS_CONSTANTS.globalFloatingRightBannerDataKey
              : ADS_CONSTANTS.globalFloatingLeftBannerDataKey
          }
        ></ins>
      </div>
    </div>
  );
};

export default GlobalFloatingSideBanner;
