import { Chain, createClient } from 'viem';
import { cookieStorage, createStorage, http } from 'wagmi';
import { injected, walletConnect } from 'wagmi/connectors';

import { WALLET_CONNECT_PROJECT_ID } from '@shared/constants';

import {
  arbitrum,
  aurora,
  avalanche,
  base,
  bsc,
  fantom,
  gnosis,
  klaytn,
  mainnet,
  optimism,
  polygon,
  zkSync,
} from '@reown/appkit/networks';
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi';

import { metadata } from './configuration';

if (!WALLET_CONNECT_PROJECT_ID) {
  throw new Error('Wallet Connect Project ID is not defined');
}

const generateRPCProviderUrl = (chainId: Chain['id']) => {
  if (chainId === mainnet.id) {
    return 'https://rpc.ankr.com/eth';
  }

  if (chainId === base.id) {
    return 'https://rpc.ankr.com/base';
  }

  return undefined;
};

export const wagmiAdapter = new WagmiAdapter({
  networks: [
    mainnet,
    bsc,
    polygon,
    optimism,
    arbitrum,
    gnosis,
    avalanche,
    fantom,
    aurora,
    zkSync,
    klaytn,
    base,
  ],
  projectId: WALLET_CONNECT_PROJECT_ID,
  storage: createStorage({
    storage: cookieStorage,
  }),
  syncConnectedChain: false,
  multiInjectedProviderDiscovery: true,
  client({ chain }) {
    return createClient({
      chain,
      transport: http(generateRPCProviderUrl(chain.id), {
        timeout: 30_000,
        batch: {
          batchSize: 7,
          wait: 150,
        },
      }),
    });
  },
  connectors: [
    injected({ shimDisconnect: true }),
    walletConnect({
      projectId: WALLET_CONNECT_PROJECT_ID,
      metadata,
      qrModalOptions: {
        themeMode: 'dark',
      },
      showQrModal: false,
    }),
  ],
});

export const wagmiConfig = wagmiAdapter.wagmiConfig;

export type WagmiConfigChain = (typeof wagmiConfig)['chains'][number];

// TODO: we need to implement siwe using web3modal
// function createMessage({ nonce, address, chainId }: SIWECreateMessageArgs){
//   const message = new SiweMessage({
//     version: '1',
//     domain: window.location.host,
//     uri: window.location.origin,
//     address,
//     chainId,
//     nonce,
//     statement: 'Sign in With Ethereum.'
//   })

//   return message.prepareMessage()
// }

// const siweConfig = createSIWEConfig({
//   createMessage,
//   getNonce: () => getSIWENonce({}),
//   getSession: async () => {
//     const user = await getUserData();

//     // user.wallets[0].attributes.
//     return {
//       // address: user.wallets.

//     }
//   },
//   signOutOnDisconnect: true,
//   signOutOnAccountChange: true,
//   verifyMessage,
//   signOut
// })
