import { useId } from 'react';

import { ADS_CONSTANTS } from './AdsConstants';
import { useAppendScript } from './hooks';

const StoreSearchInlineSearchBottomBannerTextButton = ({ userId }: { userId?: number }) => {
  const id = useId();

  useAppendScript({ id, userId });

  return (
    <div
      className="w-full h-12 xl-msq:h-14 mt-5 p-2 pr-3 xl-msq:pr-4 border border-base-border-medium rounded-lg"
      id={id}
    >
      <ins
        className={ADS_CONSTANTS.className}
        data-cp-user-id={userId}
        data-key={ADS_CONSTANTS.storeSearchInlineSearchBottomBannerTextButtonDataKey}
      ></ins>
    </div>
  );
};

export default StoreSearchInlineSearchBottomBannerTextButton;
