import { useState } from 'react';

import { MagicAi } from '@ui-uikit/lib/components/icons/poly/MagicAi';

import ChatDialog from './ChatDialog';

const AiAgent = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button className="msq-btn-magic-ai" onClick={() => setIsOpen(true)}>
        <MagicAi className="icon-xs" />
        <span className="text-caption-sm-strong-a">Ask Mystic AI</span>
      </button>
      <ChatDialog onOpenChange={setIsOpen} open={isOpen} />
    </>
  );
};

export default AiAgent;
