import { useQuery } from '@tanstack/react-query';

import { getAiAssistantStatus } from '@api';

export const aiAssistantKeys = {
  status: ['aiAssistantStatus'],
};

export const useAiAssistantStatusQuery = ({ enabled }: { enabled: boolean }) => {
  return useQuery({
    queryKey: aiAssistantKeys.status,
    queryFn: () => getAiAssistantStatus(),
    enabled,
  });
};
